const CarrolselItem = (props) => {
    const card = props.card;
    return (
        <div className="item" key={card.cardId}>
            <a href={card.cardLink} target="blank">
                <img src={card.cardImage} alt="" />
            </a>
        </div>
    );
};
export default CarrolselItem;
