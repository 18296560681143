import { cards } from '../../utils/data/cardsData.js';
import OwlCarousel from 'react-owl-carousel';
import CarrolselItem from './CarrouselItem';

const Carrousel = () => {
    const options = {
        margin: 10,
        responsiveClass: true,
        dots: false,
        loop: true,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 2,
            },
            400: {
                items: 2,
            },
            600: {
                items: 3,
            },
            700: {
                items: 4,
            },
            1000: {
                items: 5,
            },
        },
    };
    return (
        <div className="container">
            <OwlCarousel cards={cards} {...options}>
                {cards.map((card) => (
                    <CarrolselItem card={card} key={card.cardId} />
                ))}
            </OwlCarousel>
        </div>
    );
};
export default Carrousel;
