import { parceirosData } from '../../utils/data/parceirosData.js';
import OwlCarousel from 'react-owl-carousel';
import CarrolselItem from '../Carrousel/CarrouselItem';

const Parceiros = () => {
    const options = {
        margin: 10,
        responsiveClass: true,
        dots: false,
        loop: true,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 2,
            },
            400: {
                items: 2,
            },
            600: {
                items: 3,
            },
            700: {
                items: 4,
            },
            1000: {
                items: 5,
            },
        },
    };
    return (
        <div className="container">
            <OwlCarousel cards={parceirosData} {...options}>
                {parceirosData.map(card => (
                    <CarrolselItem card={card} key={card.cardId} />
                ))}
            </OwlCarousel>
        </div>
    );
};
export default Parceiros;
