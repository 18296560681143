import { useEffect } from "react";
import "./sliderStyles.css";

const SlideItem = (props) => {
    const slideItem = props.item;
    return (
        <a href="#">
            <div className="carousel-item active">
                <img
                    src={slideItem.image}
                    className="d-block w-100"
                    alt={slideItem.title}
                />
                <div className="carousel-caption d-none d-md-block slide-info">
                    <h5>{slideItem.title}</h5>
                    <p>{slideItem.category}</p>
                </div>
            </div>
        </a>
    );
};
export default SlideItem;
