import destaque from "../../_assets/aNUNCIO-1.png";

const postData = [
    {
        title: "Fleamengo humilha Palmeiras",
        content: "Blalalasvkosvdfvfvfv",
        image: destaque,
        category: "destaque",
        id: 1,
    },
    {
        title: "Flamengo Campeão do Brasileirão",
        content: "Blalalasvkosvdfvfvfv",
        image: destaque,
        category: "destaque",
        id: 4,
    },
    {
        title: "Flamengo campeão da Copa do Brasil",
        content: "Blalalasvkosvdfvfvfv",
        image: destaque,
        category: "destaque",
        id: 3,
    },
    {
        title: "Flamengooooooo",
        content: "Blalalasvkosvdfvfvfv",
        image: destaque,
        category: "destaque",
        id: 2,
    },
    {
        title: "churraquinho de porco no Maracanã",
        content: "Blalalasvkosvdfvfvfv",
        image: destaque,
        category: "destaque",
        id: 5,
    },
];
export { postData };
