import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Hero from "../../components/Hero";
import { Session } from "../../components/Session";
import { postData } from "../../utils/data/postData";
import { GlobalContext } from "../../context/globalContext";
import Parceiros from "../../components/Parceiros";
import bgBlue from "../../_assets/bg-blue.png";
import bgWhite from "../../_assets/bg-white.png";
import Footer from "../../components/Footer";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import PostSlider from "../../components/PostSlider";
import { ProductContainer } from "../../components/ProductContainer";
import ProductItem from "../../components/ProductItem";
import { productsData } from "../../utils/data/productsData";
import ImagesGallery from "../../components/ImageGalery";
import axios from "axios";

const Home = () => {
    const {
        state: { posts, products },
        setState,
    } = useContext(GlobalContext);

    const userColletctionRef = collection(db, "teste");
    const [usersLoaded, setUsersLoaded] = useState(false);

    const getPosts = async () => {
        try {
            for (let page = 1; page <= 3; page++) {
                const response = await axios.get(
                    `https://cintespbr.institucional.ws/wp-json/wp/v2/posts?page=${page}&per_page=100`,
                    { headers: { "Content-Type": "application/json" } },
                );
                const data = response.data;
                data.forEach((post) => {
                    posts.push(post);
                });
            }
            const jsonString = JSON.stringify(posts, null, 2);
            const blob = new Blob([jsonString], { type: "application/json" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "data.json";
            link.click();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getPosts();
        if (posts.length === 0) {
            const newPosts = postData.map((post) => post);
            setState((prevContext) => ({
                ...prevContext,
                posts: newPosts,
            }));
        }
    }, [posts, setState]);
    useEffect(() => {
        const newProducts = productsData.map((product) => product);
        setState((prevContext) => ({
            ...prevContext,
            products: newProducts,
        }));
    }, []);

    useEffect(() => {
        const getUsers = async () => {
            const data = await getDocs(userColletctionRef);
            const usersData = data.docs.map((doc) => ({
                ...doc.data(),
                id: doc.id,
            }));
            setState((prevContext) => ({
                ...prevContext,
                users: usersData,
            }));
            setUsersLoaded(true);
        };

        if (!usersLoaded) {
            getUsers();
        }
    }, [usersLoaded, setState, userColletctionRef]);

    return (
        <>
            <Navbar />
            <Hero />
            <Session background="#ffff">
                {posts && posts.length > 0 ? (
                    <PostSlider items={posts} />
                ) : (
                    <p>Loading...</p>
                )}
            </Session>
            <Session background="#E6E6FA">
                <div className="container px-5">
                    <h1 className="display-2 text-center">O Cintesp.br</h1>
                    <p className="lead">
                        Com a missão desenvolver inovações em tecnologia
                        assistiva e viabilizar, em um menor tempo possível, a
                        transferência destas tecnologias para a sociedade e para
                        o setor produtivo, o CINTESP.Br/UFU estimulou e trabalha
                        em rede colaborativa e em articulação com agentes
                        socioeconômicos.
                    </p>
                    <p className="lead">
                        Sempre vinculado à Universidade Federal de Uberlândia, o
                        CINTESP.Br, iniciou os trabalhos em 2012 como “Núcleo De
                        Habilitação Reabilitação em Esportes Paralímpicos,
                        conhecido por NH/RESP. Ele foi criado para ser um dos
                        Núcleos brasileiros de Pesquisa e desenvolvimento em
                        tecnologia assistiva na área do esporte paralímpico.
                    </p>
                    <a href="#">Leia mais</a>
                </div>
            </Session>
            <Session backgroundimage={bgBlue}>
                <h1
                    style={{ color: "#ffffff" }}
                    className="display-2 text-center"
                >
                    Nossos Parceiros
                </h1>
                <Parceiros />
            </Session>
            <Session backgroundimage={bgWhite}>
                <div className="container px-5">
                    <h1 className="display-2 text-center">
                        Inovações Tecnológicas
                    </h1>
                    <p className="lead">
                        Atualmente, o Brasil é uma potência paralímpica, estando
                        entre as dez forças do esporte paralímpico mundial.
                        Desde os jogos de Londres, em 2012, onde os atletas
                        Brasileiros atingiram a 7ª colocação no quadro de
                        medalhas, o esporte paralímpico cresceu alcançando o 8º
                        lugar no ranking de medalhas na Olimpíada de 2016. Para
                        isso, os investimentos estatais foram fundamentais e o
                        papel do Comitê Paralímpico Brasileiro (CPB) vêm sendo
                        de fundamental importância para esta evolução. Neste
                        caso, o Centro Paralímpico Brasileiro, inaugurado em
                        fevereiro de 2016, vêm auxiliando os diversos atletas
                        com uma grande infraestrutura para treinamento em
                        diversas modalidades esportivas.
                    </p>
                    <a href="#">Leia mais</a>
                    <ProductContainer>
                        {products.map((product) => {
                            return <ProductItem product={product} />;
                        })}
                    </ProductContainer>
                </div>
            </Session>
            <Session backgroundimage={bgBlue}>
                <div className="container p-5">
                    <h1
                        style={{ color: "#ffffff" }}
                        className="display-2 text-center"
                    >
                        Cintesp.br em Eventos
                    </h1>
                    <p
                        style={{ color: "#ffffff" }}
                        className="lead text-center"
                    >
                        Confira algumas fotos de atuações do Cintesp.br em
                        eventos
                    </p>
                </div>
                <ImagesGallery />
            </Session>
            <Footer />
        </>
    );
};
export default Home;
