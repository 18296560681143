import FormContato from "../../FormContato";

const FooterFeatures = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-5">
                    <p className="display-6">Contato</p>
                    <p className="lead">Telefone: +55 34 99158-1188</p>
                    <p className="lead">
                        Endereço:{" "}
                        <a href="">
                            {" "}
                            <b> Acesse Sede e Instalações</b>
                        </a>
                    </p>
                    <p className="lead">Email: cintespbr@cintespbr.org</p>
                    <hr />
                    <p className="lead">Sobre o Cintesp.br</p>
                    <hr />
                    <p className="lead">Parceiros</p>
                    <hr />
                    <p className="lead">Patrocinadores</p>
                    <hr />
                    <p className="lead">Pesquisadores</p>
                    <hr />
                    <p className="lead">Produtos</p>
                    <hr />
                </div>
                <div className="col-7">
                    <p className="lead">Deixe-nos um recado</p>
                    <FormContato />
                </div>
            </div>
        </div>
    );
};
export default FooterFeatures;
