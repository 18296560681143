import SlideItem from "../SlideItem";

const PostSlider = (props) => {
    const items = props.items;
    return (
        <div className="container">
            <div
                id="carouselExampleRide"
                className="carousel slide"
                data-bs-ride="true"
            >
                <div className="carousel-inner">
                    {items.map((item) => {
                        return <SlideItem item={item} key={item.id} />;
                    })}
                </div>

                <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleRide"
                    data-bs-slide="prev"
                >
                    <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Anterior</span>
                </button>
                <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleRide"
                    data-bs-slide="next"
                >
                    <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Próximo</span>
                </button>
            </div>
            <div className="d-flex w-100 justify-content-end py-3">
                <a className="btn btn-warning" href="#">
                    Acesso o Blog!
                </a>
            </div>
        </div>
    );
};
export default PostSlider;
