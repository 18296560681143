import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./index.css";
import "react-image-gallery/styles/css/image-gallery.css";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import { GlobalProvider } from "./context/globalContext";
import Home from "./pages/Home";
import App from "./App";

const router = createBrowserRouter([
    {
        element: <App />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <GlobalProvider>
        <RouterProvider router={router} />
    </GlobalProvider>,
);
