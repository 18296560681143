import Logo from "../Logo";
import "./style.css";
const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg">
            <div className="container">
                <Logo />
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarText"
                    aria-controls="navbarText"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarText">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item dropdown mx-2">
                            <a
                                className="item-nav nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdownMenuLink"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                O CINTESP.BR
                            </a>
                            <ul
                                className="dropdown-menu"
                                aria-labelledby="navbarDropdownMenuLink"
                            >
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Histórico
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Quem Somos
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Missão
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Objetivos
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Público Alvo
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Valore
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Visão
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown me-3">
                            <a
                                className="item-nav nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdownMenuLink"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                INSTITUCIONAL
                            </a>
                            <ul
                                className="dropdown-menu"
                                aria-labelledby="navbarDropdownMenuLink"
                            >
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Formas de Atuação e Redes Colaborativas
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Sede e Instações
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown  me-3">
                            <a
                                className="item-nav nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdownMenuLink"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                PARCERIAS
                            </a>
                            <ul
                                className="dropdown-menu"
                                aria-labelledby="navbarDropdownMenuLink"
                            >
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Parceiros
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Patrocinadores
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#">
                                        Pesquisadores
                                    </a>
                                </li>
                            </ul>
                        </li>

                        <li className="nav-item">
                            <a className="item-nav nav-link me-3" href="#">
                                PRODUTOS
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="item-nav nav-link me-3" href="#">
                                CNT
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="item-nav nav-link me-3"
                                href="#footer"
                            >
                                CONTATO
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
