import logo from '../../_assets/logo.png'
import './style.css'

const Logo = () => {
    const handelRedirect = () => {
        alert('Cliquei na logo')
    }
    return (
        <img src={logo} alt="Cintesp.br" className='logo' onClick={handelRedirect} />
    )
}
export default Logo