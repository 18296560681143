import mpt from '../../_assets/parceiros/mpt.png'
import cnt from '../../_assets/parceiros/cnt.png'
import mcti from '../../_assets/parceiros/mcti.png'
import praiaClube from '../../_assets/parceiros/praia.png'
import sesi from '../../_assets/parceiros/sesi.png'
import futel from '../../_assets/parceiros/futel.png'
import ufu from '../../_assets/parceiros/ufu.png'
import cnpq from '../../_assets/parceiros/mcti.png'
import fau from '../../_assets/parceiros/fau.png'
import cpb from '../../_assets/parceiros/cpb.png'
const parceirosData = [
    {
        title: 'Ministério Público do Trabalho',
        cardLink:'https://mpt.mp.br/',
        cardImage: mpt,
        cardId: 1,
    },
    {
        title: 'CNT',
        cardLink:'#',
        cardImage: cnt,
        cardId: 2,
    },
    {
        title: 'MCTI',
        cardLink:'https://www.gov.br/mcti/pt-br',
        cardImage: mcti,
        cardId: 3,
    },
    {
        title: 'Praia Clube',
        cardLink:'https://praiaclube.org.br/',
        cardImage: praiaClube,
        cardId: 4,
    },
    {
        title: 'Sesi',
        cardLink:'https://www.fiemg.com.br/unidades/clube-sesi-uberlandia/',
        cardImage: sesi,
        cardId: 5,
    },
    {
        title: 'Futel',
        cardLink:'https://www.uberlandia.mg.gov.br/prefeitura/orgaos-municipais/futel/',
        cardImage: futel,
        cardId: 6,
    },
    {
        title: 'Comitê Paralímpico Brasileiro',
        cardLink:'https://cpb.org.br/',
        cardImage: cpb,
        cardId: 7,
    },
    {
        title: 'UFU',
        cardLink:'https://ufu.br/',
        cardImage: ufu,
        cardId: 8,
    },
    {
        title: 'CNPq',
        cardLink:'https://www.gov.br/cnpq/pt-br',
        cardImage: cnpq,
        cardId: 9,
    },
    {
        title: 'FAU',
        cardLink:'https://fau.org.br/',
        cardImage: fau,
        cardId: 10,
    },

];
export {parceirosData}
