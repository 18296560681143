const ProductItem = (props) => {
    const item = props.product;
    return (
        <div className="product-display" key={item.id}>
            <img
                width={"180px"}
                height={"180px"}
                src={item.imageDestak}
                alt=""
            />
            <h4>{item.name}</h4>
        </div>
    );
};
export default ProductItem;
