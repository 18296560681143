import React, { useState } from "react";

const initialstate = {
    posts: [],
    users: [],
    products: [],
    imagesGAlery: [],
};

const GlobalContext = React.createContext();
const GlobalProvider = ({ children }) => {
    const [state, setState] = useState(initialstate);
    return (
        <GlobalContext.Provider value={{ state, setState }}>
            {children}
        </GlobalContext.Provider>
    );
};

export { GlobalContext, GlobalProvider };
