import './style.css';
import logoHero from '../../_assets/logoHero.png';
import heroTecnology from '../../_assets/heroTecnology.mp4';
import Carrousel from '../Carrousel';

const Hero = () => {
    return (
        <>
            <div className="hero">
                <video autoPlay loop muted>
                    <source src={heroTecnology} type="video/mp4" />
                    Seu navegador não suporta o elemento de vídeo.
                </video>
                <div className="content">
                    <img src={logoHero} className="logoHero" alt="Logo Cintesp.Br" />
                </div>
                <Carrousel />
            </div>
        </>
    );
};
export default Hero;
